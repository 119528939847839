import React from "react";
import image1 from "../../asset/newcss/img/product5.png"
import image2 from "../../asset/newcss/img/product2.png"
import { useLocation, useNavigate } from "react-router";
import { useState } from "react";
import { useEffect } from "react";
import { useIdle } from "./Idle";
import { getcontentlistSelector } from "../../redux/reducer/ContentListReducers";
import { useSelector } from "react-redux";
import backarrow from "../../asset/newcss/img/back-arrow.png"
const CompareProducts = () => {
    const isIdle = useIdle(60000);
    const navigate = useNavigate()
    const params = useLocation()
    const [selProdct, setSelectProduct] = useState('')
    const { state } = params
    const { filterCategories, filterVariants, product } = state
    const contentSelector = useSelector(getcontentlistSelector)

    console.log(state, "state")
    const selectProduct = (o) => {
        // localStorage.setItem("selctedProducts", JSON.stringify(o))
        setSelectProduct(o)
    }

    const recomendedProduct = () => {
        let productList = state?.filterCategories?.filter((o) => o?.productid !== product.productid)
        console.log(product, productList, "product")
        return productList
    }

    const backButton = () => {
        navigate('/contentdetails', { state: { product: state?.product, contentList: contentSelector, filterCategories: filterCategories, filterVariants: filterVariants } })

    }
    useEffect(() => {
        if (filterCategories?.length > 1) {
            const Categories = filterCategories?.filter((o) => o?.productid !== product?.productid)
            setSelectProduct(Categories[0])
        } else {
            setSelectProduct(filterCategories[0])
        }
        if (isIdle) {
            navigate('/contentdetails', { state: { product: state?.product, contentList: contentSelector, filterCategories: filterCategories, filterVariants: filterVariants } })
            console.log("check")
        }
    }, [isIdle, navigate])
    return (<div class="product_screen" id="product_transition">
        <div class="product_background">
            <div class="background_blur">
                <div class="compare_product" id="compare_suggestion">
                    <div className="compare_VS"><p className="VS_text">VS</p></div>
                    <div className="row">
                        <div className="col-lg-6 col-xl-6 compare_product_left">
                            <img src={state?.product?.imageUrl} className="compare_product_img" />
                            <div className="compare_product_img_detail">
                                <h1 className="compare_product_name">{state?.product?.productName}</h1>
                                <p className="compare_product_subtext">{state?.product?.description}</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 compare_product_right">
                            <img src={selProdct?.imageUrl} className="compare_product_img" />
                            <div className="compare_product_img_detail">
                                <h1 className="compare_product_name">{selProdct?.productName}</h1>
                                <p className="compare_product_subtext">{selProdct?.description}</p>
                            </div>
                        </div>
                    </div>
                    {recomendedProduct().length > 0 && <div className="row">
                        <div className="product_suggestion">
                            <div className="row suggestion_card m-0">
                                <p className="suggestion_title">You might be interested in</p>
                                <div className="suggestion_card_content">
                                    {recomendedProduct()?.map((o) => {
                                        return <div className="row suggestion_product_btn" onClick={() => selectProduct(o)}>
                                            <div className="col-4 suggestion_product">
                                                <img src={o?.imageUrl} className="card-img-top suggestion_product_img" />
                                            </div>
                                            <div className="col-8">
                                                <p className="suggestion_product_text text-truncate">{selProdct?.productName}</p>
                                                <div className="suggestion_rating">
                                                    <p className="suggestion_rating_text">4.1</p>
                                                    <i class="bi bi-star-fill rating_star"></i>
                                                </div>
                                                <button className="suggestion_view_btn">Compare</button>
                                            </div>
                                            {(selProdct?.productid == o?.productid) && <div className="angle_select_overlay"></div>}
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className="mx-3">
                        <button className="back_btn" onClick={() => backButton()}><img src={backarrow} className="back-arrow" /><span>Back</span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
export default CompareProducts