import axios from 'axios';
import END_POINTS, { BASE_URL } from '../config/constants'
import { store } from '../createStore'
const axiosInstance = axios.create({
    baseURL: BASE_URL,
});

const loginInstance = axios.create({
    baseURL: BASE_URL
});

const httpAuthHeader = () => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
        checkForRedirect()
    }
    const token_type = "Bearer"
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': `${token_type}${token}`,
        'offset': -330
    };

    return headers;
}
const checkForRedirect = () => {
    // return window.location.href = '/';
}
export const doGet = (url, params = {}, unAuthorize) => {
    const config = {
        method: 'GET',
        url,
        params,
        headers: httpAuthHeader(),
    };
    return axiosInstance(config)
        .then(function (response) {
            return response
        }).catch((error) => {
            if (!unAuthorize && error.response.status === 401) {
                // store.dispatch(Actions.logout())
                // return checkForRedirect();
            }
            throw error
        })
}

export const doPost = (url, data, isOffSet) => {
    const config = {
        method: 'POST',
        url,
        data,
        headers: httpAuthHeader(),
    };
    return axiosInstance(config)
        .then(function (response) {
            return response
        }).catch((error) => {
            if (error.response.status === 401) {
                return checkForRedirect();
            }
            throw error
        })
}

export const login = (url, data) => {
    const config = {
        method: 'POST',
        url,
        data,
        headers: {
            Authorization: 'Basic dmVuZG9yOnZlbmRvcg==',
        }
    };
    return loginInstance(config)
        .then((response) => {
            return response.data
        })
}
export const signUp = (url, data) => {
    const config = {
        method: 'POST',
        url,
        data,
        headers: {
            Authorization: 'Basic YWRtaW46YWRtaW4=',
        }
    };
    return loginInstance(config)
        .then((response) => {
            return response.data
        })
}

export const publicHeaders = () => {
    return {
        'Authorization': " Basic YXBleDplNHZmcWlOVUxFTEJwdDhJSzlTTk5HOUtDbng2QmlSZQ==",
        "Content-Type": "application/x-www-form-urlencoded"
    }
}
export const handleErrorToast = (error) => {
    // try {
    //     const errmsg = JSON.stringify(error)
    //     if (errmsg?.includes('500') || errmsg?.includes('401'))
    //         toast.error(Constants.API.something, {
    //             position: toast.POSITION.TOP_RIGHT,
    //             toastId: 401,
    //         });

    // } catch (e) {
    //     //
    // }
    return error;
}
export const clientCredentials = () => {
    let data = new FormData()
    data.append('grant_type', 'client_credentials')
    const config = {
        headers: publicHeaders()
    };
    return axiosInstance.post(END_POINTS.CLIENT_CREDENTIALS, data, config)
        .then(function (response) {
            return response.data
        }).catch((error) => {
            throw handleErrorToast(error)
        })
}
export const clientCredentialsHeaders = () => {
    const token = store?.getState()?.CLIENT_CREDENTIALS?.token?.access_token;
    console.log(token, "token")
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    }
}
export const doClientGet = (url, params) => {
    const config = {
        params,
        headers: clientCredentialsHeaders(),
    };
    return axiosInstance.get(url, config)
        .then(function (response) {
            return response.data
        }).catch((error) => {
            throw handleErrorToast(error)
        })
}

export const doClientPost = (url, data) => {
    const config = {
        headers: clientCredentialsHeaders(),
    };
    return axiosInstance.post(url, data, config)
        .then(function (response) {
            return response.data
        }).catch((error) => {
            throw handleErrorToast(error)
        })
}