import { all, put, takeLatest } from "redux-saga/effects";
import END_POINTS from "../../config/constants";
import { doGet, doPost } from "../../Service";
import action from "../action";

export function* contentListSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.CONTENT, params)
        if (callback) {
            callback(response)
        }
        yield put(action.ContentListSuccess(response))
    } catch (error) {
        if (callback) {
            callback(error)
        }
        yield put(action.ContentListFailure(error))
    }
}

export default function* contentWatcher() {
    yield all([
        takeLatest(action.CONTENT_LIST, contentListSaga),
    ]);
}