import React from 'react'
import { withRouter } from 'react-router-dom'
class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            username: '',
            password: '',
            error: '',
            isChecked: false,
            isRevealPassword: false,
            showPasswordState: '',
            type: 'password'
        }
    }
    componentDidMount() {
        let { username, password, isChecked } = this.state
        username = localStorage.getItem("username")
        password = localStorage.getItem("password")
        isChecked = ''
        if (username || password) {
            isChecked = true
        } else {
            isChecked = false
        }
        this.setState({ username, password, isChecked })
    }
    handleChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value })
    }
    rememberMe(e) {
        let checked = e.target.checked
        if (checked) {
            this.setState({ isChecked: true })
        } else {
            this.setState({ isChecked: false })
        }
    }
    managePasswordIcon() {
        this.setState(({ type, showPasswordState }) => ({
            type: type === 'password' ? 'text' : 'password',
            showPasswordState: showPasswordState === '-outlined' ? '' : '-outlined'
        }))
    }
    render() {
        const { isChecked, username, type, password, showPasswordState } = this.state
        return (
            <div className="login-body">
                <div className="splash-container">
                    <div className="card ">
                        <div className="card-header text-center">
                            <p>Test</p>
                            {/* <img className="logo-svg" src={Logo} alt="Logo" /> */}
                        </div>

                        <div className="card-body">
                            <form>
                                <div className="form-group">
                                    <input className="form-control form-control-lg" name="username" id="username"
                                        type="text"
                                        placeholder="Username" autoComplete="off"
                                        onChange={(e) => this.handleChange(e)} value={username || ''} />

                                </div>
                                <div className="form-group">
                                    <div className="p-relative">
                                        <input className="form-control form-control-lg"
                                            name="password" id="password"
                                            type={type} placeholder="Password" onChange={(e) => this.handleChange(e)} value={password || ''} />
                                        <i className={`login-show-password icon material-icons${showPasswordState}`}
                                            onClick={() => this.managePasswordIcon()} >
                                            remove_red_eye
                                        </i>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="custom-control custom-checkbox">
                                        <input className="custom-control-input" type="checkbox" checked={isChecked} onChange={(e) => this.rememberMe(e)} /><span className="custom-control-label">Remember Me</span>
                                    </label>
                                </div>
                                <button className="btn btn-primary btn-lg btn-block" type="submit" >Sign in</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}

export default Login