let BASE_URL_SOCKET_IO = 'ws://192.168.1.38:9000'
let SOCKET_IO_PATH = '/ws/socket-server'

let socket;

export const startSocketConnect = (callback) => {

    socket = new WebSocket(`${BASE_URL_SOCKET_IO}${SOCKET_IO_PATH}`);
    // socket.addEventListener('open', (event) => {
    //     console.log('Socket opened', event)
    // })
    // socket.addEventListener('message', (event) => {
    //     console.log('Message from server ', event.data);
    //     callback(event.data)
    // });
    socket.onopen = (event) => {
        console.log('Socket connected');
    };
    socket.onmessage = (event) => {
        // console.log('Received websocket message: ', event);
        let data = JSON.parse(event.data);
        // console.log(data)
        callback(data)
    }
    socket.onclose = (event) => {
        console.log('Socket disconnected');
    };

}

export const stopSocketConnect = () => {
    socket.close()
};