
import React from 'react';
import { Route, Routes, Switch } from "react-router-dom";
import "./asset/css/bootstrap.min.css";
import "./asset/css/fonts/fontawesome/css/fontawesome-all.css";
import "./asset/css/style.css";
import "./asset/css/custom.css";
import '../src/asset/newcss/css/style.css'
// import '../src/asset/newcss/font'
import Login from './container/Login';
import ContentList from './container/Content';
import { Layout } from './component/Layout';
import ContentDetails from './container/Content/ContentDetails';
import CompareProducts from './container/Content/CompareProducts';


function App() {
  return (<div className='overall_scroll'>
    <Routes>
      {/* <Route path={'/'} element={<Layout />}> */}
      <Route path='/' element={<ContentList />}></Route>
      <Route path='/contentdetails' element={<ContentDetails />}></Route>
      <Route path='/compareproducts' element={<CompareProducts />}></Route>

      {/* </Route> */}
    </Routes>
  </div>
  );

}

export default App;
