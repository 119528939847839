import { Action } from "@remix-run/router";
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import END_POINTS, { BASE_URL } from "../../config/constants";
import action from "../../redux/action";
import { getcontentlistSelector, getselectedProductSelector } from "../../redux/reducer/ContentListReducers";
import { doGet } from "../../Service";
import { startSocketConnect } from "../../Socket/socket";
import image1 from "../../asset/newcss/img/product3.png"
import compare_icon from "../../asset/newcss/img/compare.png"
import { useIdle } from "./Idle";
import { async } from "q";

const ContentDetails = () => {
    const isIdle = useIdle(60000);
    // const history = useHistory()
    const dispatch = useDispatch()
    const params = useLocation()
    const { state } = params
    const [select, setSelect] = useState('')
    const [selectReview, setSelectReview] = useState(false)
    const [productImage, setProductImage] = useState("")
    const [imageloadCount, setimageLoad] = useState(1)
    const [contentList, setContentList] = useState([])
    const contentSelector = useSelector(getcontentlistSelector)
    console.log(state, "jj")
    const navigate = useNavigate()
    useEffect(() => {
        // productImageAni()
        // const callBack = (res) => {
        //     if (res?.data?.response_code == 200) {
        //         setContentList(res?.data?.response)
        //     }
        // }
        // dispatch(action.Content("", callBack))
        startSocketConnect((data) => {
            socket(data)
        })
        let selectproduct = localStorage.getItem('selctedProducts')
        let product = JSON.parse(selectproduct)
        if (product == null) {
            navigate('/')
        } else {
            setSelect(product)
            setProductImage(product?.imageUrl)
        }
        if (isIdle) {
            // navigate("/");
        }


    }, [isIdle, navigate])


    const socket = (data) => {
        let contentlist = localStorage.getItem("contentlist")
        let content = JSON.parse(contentlist)
        let selectproduct = localStorage.getItem('selctedProducts')
        let product = JSON.parse(selectproduct)
        if (data.type == "product_lift") {
            let findProduct = content?.find((f) => f?.productid == data.productId)
            let filterVariants = content?.filter((f) => f?.variants == findProduct?.variants)
            if (findProduct?.productid !== product?.productid || product == '') {
                setSelect({})
                selectProduct(findProduct)
            }
        }
    }

    const compareProduct = (o) => {
        let contentlist = localStorage.getItem("contentlist")
        let content = JSON.parse(contentlist)
        let filterVariants = content?.filter((f) => f?.variantId == select?.variantId)
        let filterCategories = content?.filter((f) => f?.categoryId == select?.categoryId)
        navigate('/compareproducts', { state: { product: select, filterCategories: filterCategories, filterVariants: filterVariants } })
    }
    const selectProduct = (o) => {
        console.log(o, "onb")
        setSelect(o)
        setProductImage(o?.imageUrl)
        let contentlist = localStorage.getItem("contentlist")
        let content = JSON.parse(contentlist)
        console.log(o, content, "content")
        let filterVariants = content?.filter((f) => f?.variantId == o?.variantId)
        let filterCategories = content?.filter((f) => f?.categoryId == o?.categoryId)
        navigate('/contentdetails', { state: { product: o, contentList: content, filterCategories: filterCategories, filterVariants: filterVariants } })
        localStorage.setItem("selctedProducts", JSON.stringify(o))

    }


    return <>
        {select?.id && <div class="product_screen" id="product_transition" >
            <div class="product_background">
                <div class="background_blur">
                    <div class="product_details">
                        <div class="row">
                            <div class="col-lg-12 col-xl-6 product_details_left">
                                <h1 class="product_title">{select?.productName}</h1>
                                <div className="row">
                                    <div className="col-lg-9 col-xl-8">
                                        <div className="row m-0">
                                            <img src={productImage} className="product_img" />
                                        </div>
                                        <div className="row variant_card m-0">
                                            <div className="variant_card_content">
                                                {state?.filterVariants.map((o) => {
                                                    return <button className="variant_product_btn" onClick={() => selectProduct(o)}>
                                                        <div className="card variant_product">
                                                            <img src={o.imageUrl} className="card-img-top variant_product_img" />
                                                            <p className="variant_product_text text-truncate">{o?.productName}</p>
                                                        </div>
                                                        {(select?.productid === o?.productid) && < div className="variant_select_overlay"></div>
                                                        }
                                                    </button>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-xl-4">
                                        <div className="row angle_card m-0">
                                            {state?.filterCategories?.filter(f => f?.productid !== select?.productid).length > 0 && <button className="compare_btn" onClick={() => compareProduct()}><img src={compare_icon} className="compare_icon" /><span className="compare_text">Compare</span></button>}
                                            <div className="angle_card_content">
                                                <button className="angle_product_btn" onClick={() => setProductImage(select?.imageUrl)}>
                                                    <div className="card angle_product">
                                                        <img src={select?.imageUrl} className="card-img-top angle_product_img" />
                                                        {/* <p className="angle_product_text text-truncate">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Atque, ex? Saepe, ipsa mollitia obcaecati praesentium cumque aspernatur quae porro aliquam perferendis, esse ea, earum unde consectetur distinctio facere illo aliquid?</p> */}
                                                    </div>
                                                    {(productImage == select?.imageUrl) && <div className="angle_select_overlay"></div>}
                                                </button>
                                                {select?.imageangle0 && <button className="angle_product_btn" onClick={() => setProductImage(select?.imageangle0)}>
                                                    <div className="card angle_product">
                                                        <img src={select?.imageangle0} className="card-img-top angle_product_img" />
                                                        {/* <p className="angle_product_text text-truncate">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Atque, ex? Saepe, ipsa mollitia obcaecati praesentium cumque aspernatur quae porro aliquam perferendis, esse ea, earum unde consectetur distinctio facere illo aliquid?</p> */}
                                                    </div>
                                                    {(productImage == select?.imageangle0 || imageloadCount === 2) && <div className="angle_select_overlay"></div>}
                                                </button>}
                                                {select?.imageangle1 && <button className="angle_product_btn" onClick={() => setProductImage(select?.imageangle1)}>
                                                    <div className="card angle_product">
                                                        <img src={select?.imageangle1} className="card-img-top angle_product_img" />
                                                        {/* <p className="angle_product_text text-truncate">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Atque, ex? Saepe, ipsa mollitia obcaecati praesentium cumque aspernatur quae porro aliquam perferendis, esse ea, earum unde consectetur distinctio facere illo aliquid?</p> */}
                                                    </div>
                                                    {(productImage == select?.imageangle1 || imageloadCount === 3) && <div className="angle_select_overlay"></div>}

                                                </button>}
                                                {select?.imageangle2 && <button className="angle_product_btn" onClick={() => setProductImage(select?.imageangle2)}>
                                                    <div className="card angle_product">
                                                        <img src={select?.imageangle2} className="card-img-top angle_product_img" />
                                                        {/* <p className="angle_product_text text-truncate">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Atque, ex? Saepe, ipsa mollitia obcaecati praesentium cumque aspernatur quae porro aliquam perferendis, esse ea, earum unde consectetur distinctio facere illo aliquid?</p> */}
                                                    </div>
                                                    {(productImage == select?.imageangle2 || imageloadCount === 4) && <div className="angle_select_overlay"></div>}

                                                </button>}
                                                {select?.imageangle3 && <button className="angle_product_btn" onClick={() => setProductImage(select?.imageangle3)}>
                                                    <div className="card angle_product">
                                                        <img src={select?.imageangle3} className="card-img-top angle_product_img" />
                                                        {/* <p className="angle_product_text text-truncate">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Atque, ex? Saepe, ipsa mollitia obcaecati praesentium cumque aspernatur quae porro aliquam perferendis, esse ea, earum unde consectetur distinctio facere illo aliquid?</p> */}
                                                    </div>
                                                    {productImage == select?.imageangle3 && <div className="angle_select_overlay"></div>}
                                                </button>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div>
                                    <img src={image1} className="product_img" />
                                    <h1 class="product_title">{select?.productName}</h1>
                                </div> */}
                            </div>
                            <div class="col-lg-12 col-xl-6 product_details_right">
                                <div class="row">
                                    {/* <div class="col-12 product_details_right_top">
                                    <h1 class="product_title">{select?.productName}</h1>
                                    <ul>
                                        <li>{select?.description}</li>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
                                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Id laboriosam vero
                                            velit aut hic quasi veniam illum voluptate possimus.</li>
                                    </ul>
                                </div> */}
                                    <div class="col-12 product_details_right_bottom">
                                        <div className="row m-0">
                                            <div class="card">
                                                <div class="card-header">
                                                    <button class={!selectReview ? "active_tap_btn m-0" : "tap_btn m-0"} onClick={() => setSelectReview(false)}>Details</button>
                                                    <button class={selectReview ? "active_tap_btn m-0" : "tap_btn m-0"} onClick={() => setSelectReview(true)}>Review</button>
                                                </div>
                                                {!selectReview ? <div class="card-body card-body_active_detail">
                                                    <p>{select?.description}</p>
                                                </div>
                                                    : <div class="card-body card-body_rating_detail">
                                                        <div class="row">
                                                            <div class="col-4 rating_detail_left">
                                                                <div>
                                                                    <h1 class="rating_value">4.5</h1>
                                                                    <i class="bi bi-star-fill rating_star"></i>
                                                                    <i class="bi bi-star-half rating_star"></i>
                                                                    <i class="bi bi-star rating_star"></i>
                                                                    <h1 class="rating_text_value">Over all customer rating</h1>
                                                                </div>
                                                            </div>
                                                            <div class="col-8 rating_detail_right">
                                                                <div>
                                                                    <div class="reviewer_profile">
                                                                        <img src="../asset/img/profile1.jpg" class="reviewer_profile_pic" />
                                                                        <h1 class="reviewer_name">{select?.productName}</h1>
                                                                    </div>
                                                                    <h1 class="review_comment_title">Good Quality</h1>
                                                                    <p class="reviewer_comments">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet asperiores, veniam minus tempora rem nisi iure deserunt aperiam voluptates vitae.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {state?.filterCategories?.filter(f => f?.productid !== select?.productid).length > 0 && <div className="product_suggestion">
                                    <p className="suggestion_title">You might be interested in</p>
                                    <div className="row suggestion_card m-0">
                                        <div className="suggestion_card_content">
                                            {state?.filterCategories?.filter(f => f?.productid !== select?.productid).map((o) => {
                                                return <div className="row suggestion_product_btn" onClick={() => selectProduct(o)}>
                                                    <div className="col-4 suggestion_product">
                                                        <img src={o?.imageUrl} className="card-img-top suggestion_product_img" />
                                                    </div>
                                                    <div className="col-8">
                                                        <p className="suggestion_product_text text-truncate">{o?.productName}</p>
                                                        <div className="suggestion_rating">
                                                            <p className="suggestion_rating_text">4.1</p>
                                                            <i class="bi bi-star-fill rating_star"></i>
                                                        </div>
                                                        <button className="suggestion_view_btn">View More</button>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
    </>
}
export default ContentDetails