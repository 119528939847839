import { Action } from "@remix-run/router";
import React, { useEffect } from "react"
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import END_POINTS, { BASE_URL } from "../../config/constants";
import action from "../../redux/action";
import { getcontentlistSelector } from "../../redux/reducer/ContentListReducers";
import { doGet } from "../../Service";
import { startSocketConnect } from "../../Socket/socket";

const ContentList = () => {
    const dispatch = useDispatch()
    const contentSelector = useSelector(getcontentlistSelector)
    const navigate = useNavigate()
    useEffect(() => {
        startSocketConnect((data) => {
            socket(data)
        })
        dispatch(action.Content())
    }, [])

    const viewDetails = (o) => {
        let contentlist = localStorage.getItem("contentlist")
        let content = JSON.parse(contentlist)
        console.log(o, content, "content")
        let filterCategories = content?.filter((f) => f?.categoryId === o?.categoryId)
        let filterVariants = content?.filter((f) => f?.variantId == o?.variantId)
        console.log(filterCategories, content, "filterCategories")
        localStorage.setItem("selctedProducts", JSON.stringify(o))
        navigate('/contentdetails', { state: { product: o, contentList: content, filterCategories: filterCategories, filterVariants: filterVariants } })
    }
    const socket = (data) => {
        console.log(data.productId, "data")
        let contentlist = localStorage.getItem("contentlist")
        let content = JSON.parse(contentlist)
        if (data.type == "product_lift") {
            let findProduct = content?.find((f) => f?.productid == data.productId)
            let filterVariants = content?.filter((f) => f?.variants == findProduct?.variants)
            if (findProduct) {
                viewDetails(findProduct)
            }
        }
    }
    let url = "http://localhost/joomla";
    // return <div className="container-fluid dashboard-content ">
    //     <div class="row">
    //         {contentSelector?.map((o) => {
    //             return (
    //                 <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12" onClick={() => viewDetails(o)}>
    //                     <div class="card">
    //                         <div class="card-body">
    //                             <img height='150px' width="150px" src={o?.imageUrl}></img>
    //                             <h5 class="text-muted">{o?.productName}</h5>
    //                             {/* <div class="metric-value d-inline-block">
    //                                 <h1 class="mb-1">$12099</h1>
    //                             </div>
    //                             <div class="metric-label d-inline-block float-right text-success font-weight-bold">
    //                                 <span><i class="fa fa-fw fa-arrow-up"></i></span><span>5.86%</span>
    //                             </div> */}
    //                         </div>
    //                         <div id="sparkline-revenue"></div>
    //                     </div>
    //                 </div>
    //             )
    //         })}

    //     </div>
    // </div>
    return <div class="welcome_page">
        <div class="welcome_background">
            <div>
                <div className="container-fluid dashboard-content ">
                    <div class="row d-flex justify-content-center">
                        {/* {contentSelector?.map((o) => {
                            return (
                                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12" onClick={() => viewDetails(o)}>
                                    <div class="card">
                                        <div class="card-body">
                                            <img height='150px' width="150px" src={o?.imageUrl}></img>
                                            <h5 class="text-muted">{o?.productName}</h5>

                                        </div>
                                        <div id="sparkline-revenue"></div>
                                    </div>
                                </div>
                            )
                        })} */}
                        <div>
                            {/* <h1 class="banner_title">pick up to discover</h1> */}
                            <p class="banner_subtext">Please lift any of the product below to learn more about them.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default ContentList