import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './createStore';
// import { PersistGate } from 'redux-persist/integration/react';
// *************** Store configuration *********
// import createSagaMiddleware from 'redux-saga';
// import userReducer from './User/Reducer'
// import rootWatchers from './rootSagaWatchers';
// import {createStore, applyMiddleware, combineReducers} from 'redux';



// const sagaMiddleware = createSagaMiddleware();
// const store = createStore(
//     combineReducers({
//         userDetailState: userReducer,
//     }),
//     {},
//     applyMiddleware(sagaMiddleware)
// );
// sagaMiddleware.run(rootWatchers);

// *************** Store configuration *********
// const preloadedState = window && window.__PRELOADED_STATE__;
// const {store, persistor} = createStore
ReactDOM.render(
  <Router>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>      </Router>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
