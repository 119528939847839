import { useEffect, useState } from "react";

export function useIdle(duration) {
    const [isIdle, setIsIdle] = useState(false);

    useEffect(() => {
        let timeoutId;

        function handleTimeout() {
            setIsIdle(true);
        }

        function handleActivity() {
            setIsIdle(false);
            clearTimeout(timeoutId);
            timeoutId = setTimeout(handleTimeout, duration);
        }

        document.addEventListener("mousemove", handleActivity);
        document.addEventListener("keydown", handleActivity);

        timeoutId = setTimeout(handleTimeout, duration);

        return () => {
            clearTimeout(timeoutId);
            document.removeEventListener("mousemove", handleActivity);
            document.removeEventListener("keydown", handleActivity);
        };
    }, [duration]);

    return isIdle;
}